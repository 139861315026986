<template>
  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <!-- <BaseIcon class="ic-24 grey" :icon="currentId ? 'edit' : 'plus-circle'" /> -->
        <h1 class="title">{{ currentId ? 'Редактирование записи' : 'Новая запись' }}</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="form-add pos-r z0 flex p-10 fd-c" id="form-add">
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />

        <!-- Tags -->
        <FormTags
          type="text"
          label="Теги"
          placeholder="Выберите или добавьте новые теги"
          class="p-10"
          tagsName="postsTags"
          dispatcher="GET_POSTS_TAGS"
          :canAdd="isAdmin"
          v-model="formData.tags"
        />

        <!-- Text & Grid -->
        <div class="p-10">
          <div class="spec">
            <div class="ddown-inner-text flex ggap-5">
              <SearchLinks
                name="Пользователи"
                icon="user"
                :list="userList"
                :propLoad="isLoadUsers"
                @search="searchUsers"
                @setLink="setLink('user', $event)"
              />
              <SearchLinks
                name="Сообщества"
                icon="users"
                :list="communityList"
                :propLoad="isLoadCommunities"
                @search="searchCommunities"
                @setLink="setLink('community', $event)"
              />
            </div>
            <FormEditor
              label=""
              placeholder="Начните писать, сюда можно вставлять: ссылки на файлы, ссылки картинок и ссылки на видео с YouTube."
              class="big empty pt-10"
              required
              v-model="formData.body"
            />
          </div>
          <template v-if="formData.links && formData.links.length">
            <BaseGrid :media="formData.links" @mediaUpdated="formData.links = $event" isEdit />
          </template>
        </div>

        <!-- Upload file -->
        <div class="p-10 b-t ggap-20">
          <FormFileGrid v-model="formData.documents" />
        </div>
        <div class="divider-h"></div>
        <Author v-model:obj_type="formData.obj_type" class="mt-10" v-model:obj_id="formData.obj_id" />
      </div>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c jc-fe ggap-20" :class="{ 'form-add__disabled': isLoad }">
        <div class="flex ai-c ggap-10">
          <button class="btn transparent-grey" @click.prevent="closeAction">Отмена</button>
          <button class="btn primary" :disabled="cantSend" @click.prevent="submitAction">
            {{ currentId ? 'Сохранить изменения' : 'Опубликовать' }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import axios from 'axios'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineEmits, defineProps, computed, reactive, ref, watch, nextTick, toRefs } from 'vue'
import { BaseLoad, ModalWrapper, FormEditor, FormTags, FormFileGrid, BaseGrid } from '@/components'
import SearchLinks from './SearchLinks.vue'
import Author from './Author.vue'
import posts from '@/api/modules/posts'
import { getLinkData, $busEmit } from '@/plugins'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['close'])

// Props
const props = defineProps({
  currentId: {
    type: [Number, String],
    default: 0
  }
})

// Data
momentDurationFormatSetup(moment)
const { currentId } = toRefs(props)
const store = useStore()
const route = useRoute()
const isLoad = ref(false)
const isLoadUsers = ref(false)
const isLoadCommunities = ref(false)
const isDdownText = ref(false)
const formData = reactive({
  body: '',
  tags: [],
  links: [], // Сылка на youtube видео || ссылка на картинку в интернете
  documents: [], // Любые файлы с компа кроме видео
  obj_type: 'Community', // 'User || Community || Team',
  obj_id: 0, // ID юзера || ID сообщества || ID команды
  owner: {} // От имени кого был опубликован пост
})

// Computed
// const isMobile = computed(() => store.getters.isMobile)
const userList = computed(() => store.getters['helpers/users'])
const communityList = computed(() => store.getters['helpers/communities'])
const adminList = computed(() => store.getters['helpers/adminList'])
const profile = computed(() => store.getters['profile'])
const isAdmin = computed(() => profile.value?.negotiation_role === 'Admin')
const cantSend = computed(() => {
  const body = formData.body.split('<p>&nbsp;</p>').join('')
  const some = [!!body.trim(), !!formData.documents?.length, !!formData.links?.length]

  return !some.some((el) => el === true) || !formData.obj_id
})

// Watch
watch(
  () => formData.body,
  () => checkText()
)
watch(formData.documents, () => {
  setUploadImages()
})
watch(adminList, () => {
  setCurrentAuthor()
})

// Created
if (currentId.value) getPost()
formData.owner = {
  id: profile.value.id,
  fullname: profile.value.fullname,
  avatar: profile.value.avatar
}

// Methods
function getPost() {
  isLoad.value = true
  posts
    .get(currentId.value)
    .then(({ data }) => {
      formData.body = data?.data?.body || ''
      formData.obj_type = data?.data?.object_type
      formData.obj_id = data?.data?.object_id
      formData.owner = data?.data?.owner
      formData.links = data?.data?.links || []
      formData.tags = data?.data?.tags || []

      for (let i = 0; i < data?.data?.documents.length; i++) {
        const element = data?.data?.documents[i]
        formData.documents.push({
          ...element,
          file: null
        })
      }
    })
    .finally(() => (isLoad.value = false))
}
function closeAction() {
  emits('close')
  formData.body = ''
  formData.tags = []
  formData.links = []
  formData.documents = []
}
async function getPlainText() {
  return new Promise((resolve) => {
    let str = formData.body.replace('<p>&nbsp;</p>', '')
    str = DOMPurify.sanitize(str.trim())
    resolve(str)
  })
}
async function checkText() {
  getLinkData(false)
  const plainText = await getPlainText()
  const arrText = plainText.split(' ').filter((el) => !!el && el !== '&nbsp;' && el !== '&nbsp;&nbsp;')

  for (let i = 0; i < arrText.length; i++) {
    const element = arrText[i]

    // Short url video
    if (element.includes('https://youtu.be')) {
      const clean = element.includes('&nbsp;') ? element.replace('&nbsp;', '') : element
      const arr = clean.split('/')
      const url = arr[3]

      if (!url.includes('http')) {
        if (!url.includes('?')) {
          if (formData?.links?.findIndex((el) => el.url === url) === -1) {
            const info = await getYoutubeData(url)
            formData.links.push({
              id: new Date().getTime() / 1000,
              type: 'video',
              url,
              info
            })
          }
        } else {
          const finalArr = url.split('?')
          const finalUrl = finalArr[0]

          if (formData?.links?.findIndex((el) => el.url === finalUrl) === -1) {
            const info = await getYoutubeData(finalUrl)
            formData.links.push({
              id: new Date().getTime() / 1000,
              type: 'video',
              url: finalUrl,
              info
            })
          }
        }
      }
    }

    // Full url video
    else if (element.includes('https://www.youtube.com/watch?')) {
      const clean = element.includes('&nbsp;') ? element.replace('&nbsp;', '') : element
      const preArr = clean.split('?')
      const arr = preArr[1].split('&')
      const url = arr[0].slice(2)

      if (!url.includes('http')) {
        if (formData?.links?.findIndex((el) => el.url === url) === -1) {
          const info = await getYoutubeData(url)
          formData.links.push({
            id: new Date().getTime() / 1000,
            type: 'video',
            url,
            info
          })
        }
      }
    }

    // Image
    else if (element.includes('http://') || element.includes('https://')) {
      const clean = element.includes('&nbsp;') ? element.replace('&nbsp;', '') : element
      const img = new Image()
      img.src = clean
      img.onload = function () {
        if (formData?.links?.findIndex((el) => el.url === clean) === -1) {
          formData.links.push({
            id: new Date().getTime() / 1000,
            type: 'image',
            url: clean
          })
        }
      }
      img.onerror = function () {
        console.log('картинка не существует', clean)
      }
    }

    // Inner ddown
    else {
      if (element.indexOf('@') === 0) {
        isDdownText.value = true
      } else {
        isDdownText.value = false
      }
    }
  }
}
async function getYoutubeData(id) {
  const key = 'AIzaSyDXb1iTpUBofUkooXQTP9Si-SSwT1mz790'
  const { data } = await axios.get(
    `https://www.googleapis.com/youtube/v3/videos?id=${id}&part=contentDetails&part=snippet&key=${key}`
  )

  const video = data?.items[0]
  const input = video?.contentDetails.duration
  const reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/
  let hours = 0,
    minutes = 0,
    seconds = 0,
    totalseconds

  if (reptms.test(input)) {
    var matches = reptms.exec(input)
    if (matches[1]) hours = Number(matches[1])
    if (matches[2]) minutes = Number(matches[2])
    if (matches[3]) seconds = Number(matches[3])
    totalseconds = hours * 3600 + minutes * 60 + seconds
  }

  const duration = moment.duration(totalseconds, 'seconds')
  const formatted = duration.format('hh:mm:ss')
  const thumbnails = {}

  if (video?.snippet?.thumbnails) {
    for (const key in video.snippet.thumbnails) {
      if (Object.hasOwnProperty.call(video.snippet.thumbnails, key)) {
        const element = video.snippet.thumbnails[key]
        thumbnails[key] = element.url
      }
    }
  }

  return {
    time: formatted,
    channelTitle: video?.snippet?.channelTitle,
    title: video?.snippet?.title,
    thumbnails
  }
}
function setLink(type, data) {
  formData.body = `${formData.body} <a href="#${type}-${data.id}">${data.name}</a>`
}
async function submitAction() {
  if (cantSend.value) return

  isLoad.value = true
  const payload = {
    id: currentId.value ? Number(currentId.value) : new Date().getTime() / 1000,
    body: formData.body.split('<p>&nbsp;</p>').join('')
  }
  if (currentId.value) {
    payload.action = 'update'
    Object.assign(payload, formData)
  } else {
    Object.assign(payload, {
      state: 'loading',
      isSubscribed: false,
      created_at: new Date(),
      likes: '',
      views: '',
      shared: '',
      ...formData
    })
  }
  try {
    const { data } = await store.dispatch('news/POST', payload)
    store.commit('news/SET_ITEM', { id: data.data.id, payload: data.data })
    const link = `/news/${data.data.id}`
    const message = currentId.value
      ? `<a href="${link}">Пост</a> успешно изменён!`
      : `Новый <a href="${link}">пост</a> успешно создан!`

    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: message
    })
    closeAction()
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
function setUploadImages() {
  for (let i = 0; i < formData.documents.length; i++) {
    const file = formData.documents[i]
    if (
      file.mime === 'image/jpeg' ||
      file.mime === 'image/png' ||
      file.mime === 'image/svg+xml' ||
      file.mime === 'image/gif'
    ) {
      const fileUrl = URL.createObjectURL(file.file)
      if (formData?.links?.findIndex((el) => el.name === file.name) === -1) {
        formData.links.push({
          id: file.id,
          type: 'image',
          url: fileUrl,
          name: file.name,
          file: file.file
        })
      }
      nextTick(() => {
        formData.documents.splice(i, 1)
      })
    }
  }
}
async function searchUsers(searchText) {
  if (searchText) {
    try {
      isLoadUsers.value = true
      await store.dispatch('helpers/SEARCH_USERS', searchText)
    } catch (error) {
      console.log(error)
    } finally {
      isLoadUsers.value = false
    }
  }
}
async function searchCommunities(searchText) {
  if (searchText) {
    try {
      isLoadCommunities.value = true
      await store.dispatch('helpers/SEARCH_COMMUNITIES', searchText)
    } catch (error) {
      console.log(error)
    } finally {
      isLoadCommunities.value = false
    }
  }
}
function setCurrentAuthor() {
  // Если находимся на странице сообщества
  if (!currentId.value && route.fullPath.includes('communities')) {
    const index = adminList.value.findIndex((el) => el.id === Number(route.params.id) && el.type === 'Community')
    if (index !== -1) {
      formData.obj_type = 'Community'
      formData.obj_id = Number(route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-add {
  width: 700px;

  &__textarea {
    width: 100%;
    height: 200px;
    border: 0;
  }

  &__tags {
    background-color: var(--grey-l);

    &-select {
      flex: auto;

      &-input {
        border: 0;
        background-color: transparent;
        height: 30px;
      }

      &-ddown {
        box-shadow: var(--box-shadow);
      }
    }
  }

  &__disabled {
    pointer-events: none;
    filter: grayscale(1);
  }
}
@media screen and (max-width: 800px) {
  .form-add {
    width: calc(100vw - 40px);
  }
}
</style>
